import React from "react";
import { useState } from "react";
import colors from "../lib/colors";
import MUITextField from "@material-ui/core/TextField";
import FormData from 'form-data';

const baseUrl = process.env.REACT_APP_BASE_URL;

function LoginBox() {
    
    const [username, setUsername] = useState("username");
    const [password, setPassword] = useState("password");
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault()
        let path = process.env.REACT_APP_BASE_URL + "/auth/login";
        let formData = new FormData();
        formData.append("username",username);
        formData.append("password",password);

        let res = await fetch(process.env.REACT_APP_BASE_URL + "/auth/login", { method: 'post', body: formData, mode: 'cors', credentials: "include"});
        let data = await res.json();
        if (data.status == "user authenticated") {
            window.location.reload();
        } else {
           setErrorMessage("Invalid username/password");
        }
    }

    const passwordClicked = () => {
        setPassword("");
    }
    const usernameClicked = () => {
        setUsername("");
    }

    
    return(
        <div style={{display:'flex', flexDirection:'column'}}>
            You should log in:
            <div style={{color:"#ff0000"}}>{errorMessage}</div>
            <form onSubmit={handleSubmit}>
                <div className="formElements">
                    <input className='formInput' value={username} type='text' onChange={e => setUsername(e.target.value)} onClick={e => setUsername("")} />
                </div>
                <div className="formElements">
                    <input className='formInput' value={password} type='password' onChange={e => setPassword(e.target.value)} onClick={e => setPassword("")} />
                </div>
                <div className="formElements" style={{display:'flex', flexDirection:'row'}}>
                    <div>
                        <input className='button' type='submit' value='Login' text="Login" onSubmit={handleSubmit} />
                    </div>
                    <div style={{marginLeft:'10px', marginTop:'auto', marginBottom:'auto', color: colors.secondaryColor}}>
                        Forgot Password?
                    </div>
                </div>
                <div className="formElements">
                    Thingist is currently invite only.
                </div>
            </form>
        </div>
    )
}

export default(LoginBox);