import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import menu from '../menu.svg';
import { gotFeedState, usernameState, mobileMenu } from "./state";

const styles = {
    dialog: {
        position: 'absolute',
        top: '50px',
        left: '40%',
        border: 'gray solid 1px',
        borderRadius: '3px',
    },
};

function Header(props) {
    const [gotFeed, setGotFeed] = useRecoilState(gotFeedState);
    const [mobileMenuState, setMobileMenu] = useRecoilState(mobileMenu);
    const username = useRecoilValue(usernameState)
    
    const logout = async() => {
        await fetch(process.env.REACT_APP_BASE_URL + "/auth/logout", { mode: 'cors', credentials: "include", method: "get" });
        window.location.reload();
    }
    const smallScreen = window.innerWidth < 950;
    const linkColor = smallScreen ? '#000000' : '#FFFFFF';
    const wideScreen = (
        <Fragment>
            <Link style={{textDecoration:'none', color: linkColor}} to={`/profile/${username}`}>Profile</Link> | 
            <span onClick={logout}><Link style={{textDecoration:'none', color: linkColor}} onClick={logout} to="/"> Log Out</Link></span>
        </Fragment>
    );
    const mobile = <img src={menu} alt="menu" onClick={() => setMobileMenu(!mobileMenuState)}/>;

    if (props.auth == true) {
        return(
            <div id="header" style={{display: 'flex', flexDirection: 'row', width:'100%', height:'60px', backgroundColor: "#075894", paddingTop: '10px', paddingBottom: '10px'}} >
                <div style={{fontWeight: "bold", fontSize: '40px', fontFamily: 'Georgia, Serif', marginTop: 'auto', marginBottom: 'auto', marginLeft: '20px', color:"#ffffff", width:'50%'}}>
                    <Link onClick={() => setGotFeed(false)} style={{textDecoration: 'none', color: '#ffffff'}} to="/">Thingist</Link>
                </div>
                <div style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto', marginRight:'20px', width:'50%', color:'#ffffff'}}>
                    { smallScreen ? mobile : wideScreen }
                </div>
            </div>
        );
    } else {
        return(
            <div id="header" style={{display: 'flex', flexDirection: 'row', width:'100%', height:'60px', backgroundColor: "#075894", paddingTop: '10px', paddingBottom: '10px'}} >
                <div style={{fontWeight: "bold", fontSize: '40px', fontFamily: 'Georgia, Serif', marginTop: 'auto', marginBottom: 'auto', marginLeft: '20px', color:"#ffffff", width:'50%'}}>
                    <Link onClick={() => setGotFeed(false)} style={{textDecoration: 'none', color: '#ffffff'}} to="/">Thingist</Link>
                </div>
                <div style={{textAlign: 'right', marginTop: 'auto', marginBottom: 'auto', marginRight:'20px', width:'50%', color:'#ffffff'}}>
                    { smallScreen ? mobile : wideScreen }
                </div>
            </div>
        );
    }
}

export default(Header);