import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../components/layout';
import FeedItem from '../components/feedItem';
import AddItem from '../components/addItem'; 
import Feed from '../components/feed';
import CreateList from '../components/createList';

let AuthState = React.createContext();

function Construct() {
    
    return (
        <Layout>
            <CreateList />
        </Layout>
    );
}

export default withRouter(Construct);