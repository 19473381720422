import { load } from 'recaptcha-v3'
import Reaptcha from 'reaptcha';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { gotFeedState } from '../components/state';
import { useRecoilState, useRecoilValue, } from "recoil";
import Layout from '../components/layout';
import FeedItem from '../components/feedItem';
import AddItem from '../components/addItem';
import Feed from '../components/feed';
import { feedFiltersState } from '../components/state'
import { useState, useEffect } from "react";

function Register(props) {

    async function getRCToken() {
        const recaptcha = await load('6LcNmmUcAAAAAKVtsrfB0gG2eCjz-1mBJVgdVm8m')
        const token = await recaptcha.execute('register')
        console.log(token) // Will also print the token
        validateRCToken(token);
    }

    async function validateRCToken(token,version) {
        if (version == 2) {
            version = 2;
        } else {
            version = 3;
        }
        let path = `${process.env.REACT_APP_BASE_URL}/validateRecapToken?version=${version}&recapToken=${token}`;
        console.log(path);
        let res = await fetch(path, { method: 'post', mode: 'cors', credentials: "include"});
        let data = await res.json();
        console.log(data);
        if (data.success == true) {
            console.log("You are legit.")
            console.log(data.score);
        }
    }

    function onVerify(token) {
        validateRCToken(token,2);
    }

    return (
        <Layout dynamicOwner={false}>
            <div>
                <button style={{height: 100, width: 100}} onClick={getRCToken} />
                <Reaptcha sitekey="6LcIXmYcAAAAAMWp2osA7C-4COioODVV2_dRriRm" onVerify={onVerify} />
            </div>
        </Layout>
    );
}

export default withRouter(Register);