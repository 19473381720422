import React from "react";
import { GetLists } from "../lib/getListData";
import LoginBox  from "./loginBox";
import { useState, useEffect } from "react";
import { gotFeedState, authState, listsState, gotListsState, usernameState, rightPanelUserstate } from "./state";
import { useRecoilValue, useRecoilState } from "recoil";
import { Link } from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

function RightPanel(props) {
    console.log(props);
    const [profile, setProfile] = useState({success: 0, id: 0, username: "",photoUrl: ""})
    const auth = useRecoilValue(authState);
    const username = useRecoilValue(usernameState);
    const [lists, setLists] = useRecoilState(listsState);
    const [gotLists, setGotLists] = useRecoilState(gotListsState);
    const [gotFeed, setGotFeed] = useRecoilState(gotFeedState);
    const rightPanelUser = useRecoilValue(rightPanelUserstate);
    const [profileLists, setProfileLists] = useState([]);

    useEffect(() => {
        GetProfileLists();
    },[rightPanelUser])
    useEffect(() => {
            GetProfile();
    },[rightPanelUser])

    const GetProfileLists = async () => {
        let path = process.env.REACT_APP_BASE_URL + `/lists/getLists?username=${rightPanelUser}`;
        let res = await fetch(process.env.REACT_APP_BASE_URL + `/lists/getLists?username=${rightPanelUser}`, { method: 'post', mode: 'cors', credentials: "include"});
        let data = await res.json();
        setProfileLists(data);
    }

    const GetProfile = async () => {
        console.log("Getting a profile...")
        console.log(rightPanelUser);
        const path = process.env.REACT_APP_BASE_URL + `/users/getProfile?username=${rightPanelUser}`;
        console.log(path);
        let res = await fetch(process.env.REACT_APP_BASE_URL + `/users/getProfile?username=${rightPanelUser}`, { method: 'post', mode: 'cors', credentials: "include"});
        let data = await res.json();
        console.log(data);
        if (data.success == true) {
            setProfile({...data.status});
        }
    }

    if (auth) {
        return(
            <div style={{width: "100%", display: 'flex', flexDirection: 'column'}}>
                <div style={{ width: '100%', minHeight: '300px', backgroundImage: `url('https://api.thingist.com${profile.photoUrl}')`, backgroundSize: 'cover'}}></div>
                <div>{rightPanelUser}'s lists:</div>
                <div>
                    {profileLists.map((list) => {
                        return(
                            <div key={list.listId}>
                                <Link onClick={() => setGotFeed(false)} style={{color:"#000000"}} to={`/lists/${list.listId}`}>{list.title}</Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return(<div></div>);
    }
}

export default(RightPanel);