import React, { useEffect } from "react";
import { useState } from "react";
import colors from "../lib/colors";
import MUITextField from "@material-ui/core/TextField";
import FormData from 'form-data';
import { Hidden } from "@material-ui/core";
import { GetFeed } from "../lib/getFeedData";
import { GetLists } from "../lib/getListData";
import { authState, usernameState, gotFeedState, feedState, listsState, gotListsState, detailsHiddenState } from "./state";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";

const baseUrl = process.env.REACT_APP_BASE_URL;

function AddItem(props) {
    
    const auth = useRecoilValue(authState);
    const username = useRecoilValue(usernameState);

    const [feed, setFeed] = useRecoilState(feedState);
    const [gotFeed, setGotFeed] = useRecoilState(gotFeedState);
    const [lists,setLists] = useRecoilState(listsState);
    const [gotLists,setGotLists] = useRecoilState(gotListsState);

    const [title,setTitle] = useState("");
    const [url,setUrl] = useState("");
    const [text,setText] = useState("");
    const [listId,setListId] = useState(false);
    const [formIsArmed,setFormIsArmed] = useState(true);
    const [errorMessage,setErrorMessage] = useState("");
    const [detailsHidden,setDetailsHidden] = useRecoilState(detailsHiddenState);
    const [newListTitle, setNewListTitle] = useState("")
    const [newListIsArmed, setNewListIsArmed] = useState(false)
    const [addNewListHidden, setAddNewListHidden] = useState(true);
    const [file, setFile] = useState("");

    const createList = async (event) => {
        event.preventDefault();
        let path = process.env.REACT_APP_BASE_URL + "/lists/addList";
        let formData = new FormData();
        formData.append("title",newListTitle);
        let res = await fetch(process.env.REACT_APP_BASE_URL + "/lists/addList", { method: 'post', body: formData, mode: 'cors', credentials: "include"});
        let data = await res.json();
        if (data.success == true) {
            setGotLists(false);
            setNewListIsArmed(false);
            return(data.status);
        } else {
            setErrorMessage(data.status);
            return(false);
        }
    }

    const handleSubmit = async (event) => {
        let _listId = listId;
        event.preventDefault()
        if (newListIsArmed) {
            _listId = await createList(event);
            if (_listId == false) {
                return;
            }
        }

        if (formIsArmed) {
            setFormIsArmed(false);
            let path = process.env.REACT_APP_BASE_URL + "/things/addThing";
            let formData = new FormData();
            formData.append("title",title);
            formData.append("url",url);
            formData.append("text",text);
            formData.append("listId",_listId);
            formData.append("file",file);
            let res = await fetch(process.env.REACT_APP_BASE_URL + "/things/addThing", { method: 'post', body: formData, mode: 'cors', credentials: "include"});
            let data = await res.json();
            console.log(data);
            if (data.success == true) {
                console.log("It was successful.")
                setGotFeed(false);
                setTitle("");
                setText("");
                setUrl("");
                setFile("");
                setFormIsArmed(true);
            } else {
                setErrorMessage(data.status);
                setFormIsArmed(true);
            }
        } else {
            setErrorMessage("You have to specify a title.");
        }
    }

    useEffect(() => {
        if (gotLists == false) {
            GetLists(setLists,setGotLists,username);
        }
    });

    
    if (auth == true) {
        return(<div style={{display:'flex', flexDirection:'column'}}>
                Add something:
                <div style={{color:"#ff0000"}}>{errorMessage}</div>
                <form onSubmit={handleSubmit}>
                    <div className="formElements">
                        <input value={title} placeholder="Title" style={{width:'90%'}} className='formInput' type='text' onChange={e => setTitle(e.target.value)} onFocus={() => { setDetailsHidden("block")}} />
                    </div>
                    <div style={{display:detailsHidden}}>
                        <div className="formElements">
                            <input value={url} placeholder="Url" style={{width:'90%'}} className='formInput' type='text' onChange={e => setUrl(e.target.value)} />
                        </div>
                        <div className="formElements">
                            <textarea value={text} placeholder="Description" style={{width:'90%'}} className='formInput' type='text' onChange={e => setText(e.target.value)} />
                        </div>
                        <div>
                            <input style={{paddingLeft: '0px',  marginLeft:'0px'}} className="button" type="file" onChange={(e) => { setFile(e.target.files[0]); console.log(e.target.files[0])}} />
                        </div>
                        <div className="formElements">
                            <select style={{display: addNewListHidden ? "block" : "none", fontSize: '20px', fontFamily: 'Arial, Helvetica, sans-serif',fontWeight: 500}} onChange={e => setListId(e.target.value)}>
                                <option value={false}>Select a list</option>
                                {lists.map(item => (
                                    <option key={item.listId} value={item.listId}>{item.title}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <span style={{display:"flex"}} onClick={() => {setAddNewListHidden(!addNewListHidden); setNewListTitle("")}}>
                            Create a new list
                            </span>
                            <div style={{flexDirection: 'row', marginBottom: '10px',  marginTop: '10px', display: addNewListHidden ? "none" : "flex"}}>
                                <div><input value={newListTitle} placeholder="List Name" style={{width:'90%'}} className='formInput' value={newListTitle} type='text' onChange={e => setNewListTitle(e.target.value)} onFocus={() => {setNewListIsArmed(true)}} /></div>
                            </div>
                        </div>
                        
                        <div className="formElements" style={{display:'flex', flexDirection:'row'}}>
                            <div>
                                <input className='button' type='submit' value='Submit' text="Submit" onSubmit={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>) 
    } else {
        return(<div></div>)
    }
}

export default(AddItem);