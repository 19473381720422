import React, { useEffect, Component } from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../components/layout';
import FeedItem from '../components/feedItem';
import AddItem from '../components/addItem';
import { gotFeedState, feedFiltersState } from '../components/state';
import Feed from '../components/feed';
import { useRecoilState, useRecoilValue, } from "recoil";

let feedItem = {
    user: {
        ProfilePicture: "https://place-puppy.com/300x300",
        Username: "Sadie"
    },
    item: {
        ListTitle: "Foods I Like",
        ItemTitle: "Peanut Butter",
        Age: "1 day ago",
        CommentCount: 5
    }
}

function List(props) {

    const [feedFilters, setFeedFilters] = useRecoilState(feedFiltersState);
    
    useEffect(() => {
        setFeedFilters({
            listUuid: props.match.params.listUuid, 
            usernameTarget: "",
        });
    },[props])
    
    return (
        <Layout dynamicOwner={true}>
            <div>
                <AddItem />
                <Feed />
            </div>
        </Layout>
    );
}

export default withRouter(List);