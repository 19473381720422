import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { gotFeedState } from '../components/state';
import { useRecoilState, useRecoilValue, } from "recoil";
import Layout from '../components/layout';
import FeedItem from '../components/feedItem';
import AddItem from '../components/addItem';
import Feed from '../components/feed';
import { feedFiltersState } from '../components/state'
import { useState, useEffect } from "react";

let feedItem = {
    user: {
        ProfilePicture: "https://place-puppy.com/300x300",
        Username: "Sadie"
    },
    item: {
        ListTitle: "Foods I Like",
        ItemTitle: "Peanut Butter",
        Age: "1 day ago",
        CommentCount: 5
    }
}

function Home(props) {

    const [feedFilters, setFeedFilters] = useRecoilState(feedFiltersState);
    
    useEffect(() => {
        setFeedFilters({
            listUuid: "", 
            usernameTarget: "",
        });
    },[props])

    return (
        <Layout dynamicOwner={false}>
            <div>
                <AddItem />
                <Feed />
            </div>
        </Layout>
    );
}

export default withRouter(Home);