import React from "react";
import { useRecoilState } from "recoil";
import { gotFeedState, gotListsState } from "./state";
import { Link } from "react-router-dom";
import Embed from 'react-embed';


function FeedItem(props) {

    const [gotFeed, setGotFeed] = useRecoilState(gotFeedState)
    
    return(
        <div key={props.id} className='feedItem' style={{display: "flex", flexDirection:'row'}}>
            <div className='feedProfilePhoto' style={{ marginRight:'20px', height: '80px', flex: 1, backgroundImage: `url('https://api.thingist.com${props.user.ProfilePicture}')`, backgroundSize: 'cover'}} />
            <div className='feedItemBody' style={{ flex: 3, flexDirection:'column', justifyContent: 'space-between'}}>
                <div className='feedTopLine'>
                    <span className='feedUserName'>
                        <Link style={{color: "#000000"}} onClick={() => setGotFeed(false)} to={`/users/${props.user.Username}`}>{props.user.Username}</Link>
                    </span>
                    <span className='feedListTitle'>
                        <Link style={{color: "#000000"}} onClick={() => setGotFeed(false)} to={`/lists/${props.item.ListUuid}`}>{props.item.ListTitle}</Link>: 
                    </span>
                    <span className='feedItemTitle'>
                        {props.item.ItemTitle}
                    </span>
                </div>
                <div className='feedMidLine'>
                    <div>
                        <Embed url={props.item.ItemUrl} />
                        <a style={{fontSize: '0.66em', color:'#000000', display: 'inline-block', wordBreak: 'break-word', overflowWrap: 'anywhere'}} href={props.item.ItemUrl}>{typeof(props.item.ItemUrl) == 'undefined' ? "" : props.item.ItemUrl.substring(0,56)}</a>
                    </div>
                    <div>
                        {((typeof(props.item.FilePath) != "undefined") && (props.item.FilePath != "")) ?  <img style={{ maxWidth: '100%', maxHeight: '400px' }} src={`${process.env.REACT_APP_BASE_URL}/${props.item.FilePath}`} /> : ""}
                    </div>
                </div>
                <div className='feedBottomLine'>
                    <div className='commentsLink'>
                        <Link to={`/item/${props.item.ItemUuid}`}>{props.item.CommentCount} comments</Link>
                    </div>
                    <div className='feedItemAge'>
                        {props.item.Age}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default(FeedItem);