import React from 'react';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
    useRecoilState,
    useRecoilValue,
} from 'recoil';

import {
    rightPanelUserstate, 
    authState, 
    gotAuthState, 
    usernameState, 
    feedFiltersState,
    mobileMenu,
} from './state';
import Header from './header';
import RightPanelContainer from './rightPanelContainer';

const styles = {
    layout: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        height:'100vh',
        width:'90vw',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        marginTop:'20px',
        flexWrap: 'wrap',
    },
    feed: {
        flex: '3',
        width: '100%',
    },
    panel: {
        marginLeft: '20px',
        flex: '1',
        width: '100%',
    },
    mobile: {
        margin:'20px 10px 0',
    },
};

function Layout(props) {
    const [auth, setAuth] = useRecoilState(authState);
    const [username, setUsername] = useRecoilState(usernameState);
    const [gotAuth, setGotAuth] = useRecoilState(gotAuthState);
    const feedFilters = useRecoilValue(feedFiltersState);
    const [rightPanelUser, setRightPanelUser] = useRecoilState(rightPanelUserstate);
    const [mobileMenuState, setMobileMenu] = useRecoilState(mobileMenu);

    useEffect(() => {
        if (auth === false) {
            loggedIn();
            setMobileMenu(true);
        }
    },[]);

    useEffect(() => {
        console.log("I should figure out who should be on the right panel now.")
        if (props.dynamicOwner == false) {
            console.log("This page is owned by the logged in user.")
            console.log("The logged in user is: ")
            console.log(username);
            setRightPanelUser(username);
        } else {
            getRightPanelUser();
        }
    });

    const getRightPanelUser = async () => {
        console.log("This page is owned by somebody specified likely in the URL")
        console.log(feedFilters);
        if (feedFilters.usernameTarget != "") {
            console.log("We are filtering by username, in this case:")
            console.log(feedFilters.usernameTarget);
            setRightPanelUser(feedFilters.usernameTarget);
        } 
        if (feedFilters.usernameTarget == "") {
            console.log("We are filtering by list ID.  I'll get the onwer")
            console.log(feedFilters.listUuid);
            let res = await fetch(process.env.REACT_APP_BASE_URL + `/lists/getListOwner?listUuid=${feedFilters.listUuid}`, { mode: 'cors', credentials: "include", method: "get" });
            let data = await res.json();
            console.log("This is who I think owns this:");
            console.log(data);
            if (data.success == true) {
                setRightPanelUser(data.status);
            } else {
                setRightPanelUser("")
            }  
        }
        
    }

    const loggedIn = async () => {
        let res = await fetch(process.env.REACT_APP_BASE_URL + "/auth/whoami", { mode: 'cors', credentials: "include", method: "get" });
        let data = await res.json();
        setAuth(data.success);
        setGotAuth(true);
        if (data.success === true) {
            setUsername(data.status);
            setMobileMenu(false);
        }
    }
    const logout = async() => {
        await fetch(process.env.REACT_APP_BASE_URL + "/auth/logout", { mode: 'cors', credentials: "include", method: "get" });
        window.location.reload();
    }
    const smallScreen = window.innerWidth < 950;
    const smallScreenWidth = smallScreen ? { width:'100vw' } : {};
    const smallScreenContent = smallScreen ? { margin:'20px 10px 0' } : {};
    const linkColor = smallScreen ? '#000000' : '#FFFFFF';
    if (gotAuth === true) {
        return(
            <div style={{backgroundColor: '#ffffff'}}>
                <div id="layout" style={{...styles.layout, ...smallScreenWidth}}>
                    <Header auth={auth} username={username}></Header>
                    {smallScreen && mobileMenuState && (<div style={styles.mobile}>
                        <Link style={{textDecoration:'none', color: linkColor}} to={`/profile/${username}`}>Profile</Link> | 
                        <span onClick={logout}><Link style={{textDecoration:'none', color: linkColor}} onClick={logout} to="/"> Log Out</Link></span>
                        <RightPanelContainer username={rightPanelUser} />
                    </div>)}
                    <div style={{...styles.content, ...smallScreenContent}}>
                        <div style={styles.feed}>
                            {props.children}
                        </div>
                        {!smallScreen && (<div style={styles.panel}>
                            <RightPanelContainer username={rightPanelUser} />
                        </div>)}
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default Layout;
