import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Home from './pages/home';
import List from './pages/list';
import User from './pages/user';
import Profile from './pages/profile';
import Register from './pages/register';
import Construct from './pages/construct';
import themes from './lib/mui-theme';

import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import "./index.css";
import register from './pages/register';
import item from './pages/item';

ReactDOM.render((
  <RecoilRoot>
    <Router>
        <Switch>
          <Route path="/profile/:username" component={Profile} />
          <Route path="/item/:itemUuid" component={item} />
          <Route path="/construct" component={Construct} />
          <Route path="/users/:usernameTarget" component={User} />
          <Route path="/lists/:listUuid" component={List} />
          <Route path="/register" component={register} />
          <Route path="/" component={Home} />
        </Switch>
    </Router>
  </RecoilRoot>
),
document.getElementById('root'));