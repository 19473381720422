import React, { useEffect, Component } from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../components/layout';
import Item from '../components/item';
import { gotFeedState, feedFiltersState } from '../components/state';
import { useRecoilState, useRecoilValue, } from "recoil";

function Thing(props) {
    console.log(props.match.params.itemUuid);
    return (
        <Layout dynamicOwner={true}>
            <div>
                <Item thingUuid={props.match.params.itemUuid} />
            </div>
        </Layout>
    );
}

export default withRouter(Thing);