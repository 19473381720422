import React from "react";
import LoginBox  from "./loginBox";
import RightPanel from "./rightPanel";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { authState,rightPanelUserstate } from '../components/state';

function RightPanelContainer(props) {

    const auth = useRecoilValue(authState);
    const rightPanelUser = useRecoilValue(rightPanelUserstate);

    if (auth === true) {
        return(
            <div>
                <RightPanel />
            </div>
        )
    } else {
        return(
            <div>
                <LoginBox />
            </div>
        );
    }
}

export default(RightPanelContainer);