import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../components/layout';
import { feedFiltersState } from '../components/state';
import Feed from '../components/feed';
import { useRecoilState } from "recoil";

let feedItem = {
    user: {
        ProfilePicture: "https://place-puppy.com/300x300",
        Username: "Sadie"
    },
    item: {
        ListTitle: "Foods I Like",
        ItemTitle: "Peanut Butter",
        Age: "1 day ago",
        CommentCount: 5
    }
}

function User(props) {
    
    const [feedFilters, setFeedFilters] = useRecoilState(feedFiltersState);
   
    useEffect(() => {
        setFeedFilters({
            listUuid: "", 
            usernameTarget: props.match.params.usernameTarget,
        });
    },[props])

    return (
        <Layout dynamicOwner={true}>
            <div>
                <Feed />
            </div>
        </Layout>
    );
}

export default withRouter(User);