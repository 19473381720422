import React, { useEffect, useState, Component } from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../components/layout';
import FeedItem from '../components/feedItem';
import AddItem from '../components/addItem';
import { gotFeedState, feedFiltersState } from '../components/state';
import Feed from '../components/feed';
import { useRecoilState, useRecoilValue, } from "recoil";
import { rightPanelUserstate } from '../components/state';
import { Link } from 'react-router-dom';
import Embed from 'react-embed';
import Comments from '../components/comments';

function Item(props) {

    const [rightPanelUser, setRightPanelUser] = useRecoilState(rightPanelUserstate);
    const [gotFeed, setGotFeed] = useRecoilState(gotFeedState);
    const [itemDetails, setItemDetails] = useState({
                "id": 0,
                "title": "",
                "url": "",
                "text": "",
                "username": "",
                "creationDate": "",
                "listId": "",
                "listTitle": "",
                "uuid": "",
                "fileUuid": "",
                "filePath": "",
                "fileName": "",
                "profilePhotoUrl": ""
                });

    useEffect(() => {
        const getThingDetails = async () => {
            console.log("This is the path.")
            const path = process.env.REACT_APP_BASE_URL + `/things/getThing?thingUuid=${props.thingUuid}`;
            console.log(path);
            let res = await fetch(process.env.REACT_APP_BASE_URL + `/things/getThing?thingUuid=${props.thingUuid}`);
            let data = await res.json();
            setRightPanelUser(data.username);
            setItemDetails(data);
        }
        getThingDetails();
    }, []);

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{fontSize:'2em', fontWeight: 'bold'}}>{itemDetails.title}</div>
            <div><Link onClick={() => setGotFeed(false)} to={`/lists/${itemDetails.listId}`}>{itemDetails.listTitle}</Link></div>
            <div style={{marginBottom: '10px'}}>By: <Link to={`/users/${itemDetails.username}`}>{itemDetails.username}</Link></div>
            <div style={{fontSize:'0.66em'}}>{itemDetails.creationDate}</div>
            <Embed url={itemDetails.url} />
            <div style={{fontSize:'0.66em', marginBottom:'30px'}}><a href={itemDetails.url}>{itemDetails.url}</a></div>
            <div>
                {((typeof(itemDetails.filePath) != "undefined") && (itemDetails.filePath != "")) ? <img style={{ maxWidth: '100%', maxHeight: '400px' }} src={`${process.env.REACT_APP_BASE_URL}/${itemDetails.filePath}`} /> : ""}
            </div>
            <div>{itemDetails.text}</div>
            <Comments parentUuid={itemDetails.uuid} />
        </div>
    );
}

export default withRouter(Item);