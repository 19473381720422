import React, { useState, useEffect } from "react";
import colors from "../lib/colors";
import MUITextField from "@material-ui/core/TextField";
import FormData from 'form-data';
import { Hidden } from "@material-ui/core";
import FeedItem from "./feedItem";
import { authState, gotFeedState, feedState, detailsHiddenState, feedFiltersState } from "./state";
import { useRecoilState, useRecoilValue, } from "recoil";
import { GetFeed } from "../lib/getFeedData";
import AddItem from "./addItem";


const baseUrl = process.env.REACT_APP_BASE_URL;

function Feed(props) {
    const auth = useRecoilValue(authState);
    const [gotFeed, setGotFeed] = useRecoilState(gotFeedState);
    const [feed, setFeed] = useRecoilState(feedState);
    const [feedFilters, setFeedFilters] = useRecoilState(feedFiltersState);
    const [detailsHidden, setDetailsHidden] = useRecoilState(detailsHiddenState);

    const GetFeed = async () => {
    
        console.log("This is the path.")
        const path = process.env.REACT_APP_BASE_URL + `/feed?usernameTarget=${feedFilters.usernameTarget}&listUuid=${feedFilters.listUuid}`;
        console.log(path);
        let res = await fetch(process.env.REACT_APP_BASE_URL + `/feed?usernameTarget=${feedFilters.usernameTarget}&listUuid=${feedFilters.listUuid}`, { method: 'post', mode: 'cors', credentials: "include"});
        let data = await res.json();
        setFeed(data);
        setGotFeed(true);
        return(<div></div>);
    }

    useEffect(() => {
        if (gotFeed === false) {
            GetFeed();
        }
    },[feedFilters, gotFeed]);

    const formatFeed = () => {
        console.log(feed);
        let _feed = [];
            feed.forEach(function(item) {
                let _item = {
                    user: {
                        ProfilePicture: item.profilePhotoUrl,
                        Username: item.username,
                    },
                    item: {
                        ListTitle: item.listTitle,
                        ListUuid: item.listId,
                        ItemId: item.id,
                        ItemUuid: item.uuid,
                        ItemTitle: item.title,
                        ItemUrl: item.url,
                        FilePath: item.filePath,
                        Age: item.creationDate,
                        CommentCount: 0
                    }
                }
                console.log(_item);
                _feed.push(<FeedItem key={item.id} {..._item} />)
            })
        return(_feed);
    }

    return(
        <div onClick={() => setDetailsHidden('none')} >
            {formatFeed()}
            Did I get feed? {gotFeed ? "yes" : "no"}
        </div>
    )
}

export default(Feed);