import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';


export const authState = atom({
    key: 'authState',
    default: false,
  });

export const usernameState = atom({
    key: 'username',
    default: '',
})

export const feedState = atom({
    key: 'feedState',
    default: [false],
})

export const gotFeedState = atom({
    key: 'gotFeedState',
    default: false,
})

export const gotAuthState = atom({
    key: 'gotAuthState',
    default: false
})

export const listsState = atom({
    key: 'lists',
    default: [],
})

export const gotListsState = atom({
    key: "gotLists",
    default: false,
})

export const detailsHiddenState = atom({
    key: "detailsHidden",
    default: 'none',
})

export const feedFiltersState = atom({
    key: "feedFilters",
    default: {usernameTarget: "", listUuid: ""},
})

export const rightPanelUserstate = atom({
    key: "rightPanelUser",
    default: "",
})

export const mobileMenu = atom({
    key: "mobileMenu",
    default: false
})
