import React, { Component, Fragment, useRef } from 'react';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, Typography, Collapse, List } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import colors from '../lib/colors'
import Button from './button';

import MUITextField from '@material-ui/core/TextField';


import {
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableHead,
    Checkbox,
  } from '@material-ui/core';

const baseUrl = process.env.REACT_APP_BASE_URL;

const styles = {
    dialogMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '80px',
        fontSize: '16px',
        margin: '20px',
    },
    container: {
        borderLeft: "1px solid #ff0000",
    },
    tableStyle: {
        fontSize: '1.25em',
        verticalAlign: 'top',
    }
};

class Comments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            commentText: "",
            commentsHash: "",
        }
        this.commentsList = this.commentsList.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
    }

    async refreshComments() {
        const path = baseUrl + `/comments/getComments?parentUuid=${this.props.parentUuid}`;
        console.log("Path: " + path);
        let res = await fetch(baseUrl + `/comments/getComments?parentUuid=${this.props.parentUuid}`, { mode: 'cors', credentials: "include", method: "get" })
        let data = await res.json()
        this.setState({comments: data.status});
    }

    componentDidMount() {
        this.refreshComments();
    }


    handleSubmit = async (event) => {      
        event.preventDefault();
        let formData = new FormData()
        formData.append("commentText", this.state.commentText);
        formData.append("parentUuid", this.props.parentUuid)
        this.setState({ commentText: "" });
        let res = await fetch(baseUrl + "/comments/addComment", {
            method: 'post',
            credentials: 'include',
            body: formData,
        });
        let data = await res.json()

        if (data.success === true) {
            this.refreshComments();
        }
    }

    handleCommentChange(e) {
        this.setState({ commentText: e.target.value});
    }

    commentsList(comments) {
        console.log("Dis comments:");
        console.log(comments);
        let _comments = [];
        comments.forEach(function(comment) {
            _comments.push(
                <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'column'}}>
                    <div style={{fontSize: '0.66em'}}>
                        Submitted by: <Link to={`/users/${comment.username}`}>{comment.username}</Link>
                    </div>
                    <div>
                        {comment.commentText}
                    </div>
                </div>
            )
        })
        console.log("_ Comments" + _comments);
        return(_comments)
    }

    render() {

        const commentStyle = {
            container: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            textField: {
                width: 300,
                margin: 100,
            },
            //style for font size
            resize:{
              fontSize:50
            },
            }
        return (
            <div>
               <h4 style={{color: colors.secondaryColor}}>Comments</h4>
               <Fragment key={this.state.commentsHash}>
                    {this.commentsList(this.state.comments)}
                </Fragment>
               <div style={{marginBottom: '60px'}}>
                    <form style={styles.forms} onSubmit={this.handleSubmit}>
                        <MUITextField
                            id="filled-multiline-static"
                            label="Comment"
                            multiline
                            rows={4}
                            variant="filled"
                            value={this.state.commentText}
                            onChange={this.handleCommentChange}
                            style={{
                                width: '100%',
                                backgroundColor: '#FFFFFF',
                            }}
                        />
                        <Button style={{backgroundColor: colors.secondaryColor, marginTop: '20px'}} action={this.handleSubmit}>Submit</Button>
                    </form>
                </div>
                
            </div>
        )
    }
}

export default withStyles(styles)(Comments);