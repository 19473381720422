import React from "react";
import { useState } from "react";
import colors from "../lib/colors";
import MUITextField from "@material-ui/core/TextField";
import FormData from 'form-data';
import { listsState, gotListsState, usernameState } from "./state";
import { GetLists } from "../lib/getListData";
import { useRecoilState, useRecoilValue } from "recoil";

const baseUrl = process.env.REACT_APP_BASE_URL;

function CreateList() {

    const [lists, setLists] = useRecoilState(listsState);
    const [gotLists, setGotLists] = useRecoilState(gotListsState);
    const username = useRecoilValue(usernameState);

    
    const [listTitle,setListTitle] = useState("List Title");
    const [formIsArmed,setFormIsArmed] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault()
        if (formIsArmed) {
            setFormIsArmed(false);
            let path = process.env.REACT_APP_BASE_URL + "/lists/addList";
            let formData = new FormData();
            formData.append("title",listTitle);
            let res = await fetch(process.env.REACT_APP_BASE_URL + "/lists/addList", { method: 'post', body: formData, mode: 'cors', credentials: "include"});
            let data = await res.json();
            if (data.success == true) {
                window.location.assign("/lists/" + data.status);
            } else {
                setErrorMessage(data.status);
            }
            GetLists(setLists, setGotLists, username);
        } else {
            setErrorMessage("You have to specify a list title.");
        }
    }


    return(
        <div style={{display:'flex', flexDirection:'column'}}>
            Create a new list:
            <div style={{color:"#ff0000"}}>{errorMessage}</div>
            <form onSubmit={handleSubmit}>
                <div className="formElements">
                    <input className='formInput' value={listTitle} type='text' onChange={e => {setListTitle(e.target.value)}} onClick={() => {setListTitle(""); setFormIsArmed(true)}} />
                </div>

                <div className="formElements" style={{display:'flex', flexDirection:'row'}}>
                    <div>
                        <input className='button' type='submit' value='Submit' text="Submit" onSubmit={handleSubmit} />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default(CreateList);