import React, { useEffect, useState, Component } from 'react';
import { withRouter } from 'react-router-dom';

import Layout from '../components/layout';
import FeedItem from '../components/feedItem';
import AddItem from '../components/addItem';
import { gotFeedState, feedFiltersState } from '../components/state';
import Feed from '../components/feed';
import { useRecoilState, useRecoilValue, } from "recoil";

function Profile(props) {

    const [fields, setField] = useState({
        id: 0,
        username: props.match.params.username,
        photoUrl: "",
    });
    const [file, setFile] = useState("duh");

    useEffect(() => {
        GetProfile();
    },[fields.photoUrl])

    const GetProfile = async () => {
        console.log("This is the path.")
        const path = process.env.REACT_APP_BASE_URL + `/users/getProfile?username=${fields.username}`;
        console.log(path);
        let res = await fetch(process.env.REACT_APP_BASE_URL + `/users/getProfile?username=${fields.username}`, { method: 'post', mode: 'cors', credentials: "include"});
        let data = await res.json();
        if (data.success == true) {
            setField(data.status);
        }
        return(<div></div>);
    }

    const UpdatePhotoUrl = async (event) => {
        event.preventDefault()
        console.log("You are updloading a new file.")
        let formData = new FormData();
        formData.append("username",fields.username);
        formData.append("file",file);
        let res = await fetch(process.env.REACT_APP_BASE_URL + "/users/updateProfilePicture", { method: 'post', body: formData, mode: 'cors', credentials: "include"});
        let data = await res.json();
        console.log(data);

        if (data.success == true) {
            setField({...fields, photoUrl:""})
        }
    }
      

    return (
        <Layout>
            <div onClick={() => {setField({...fields, bio:"I am a cat."})}}>
                My name is {fields.username}
                <br />
                My URL is: {fields.photoUrl}
                <br />
                <form onSubmit={UpdatePhotoUrl}>
                    <input type="file" onChange={(e) => { setFile(e.target.files[0]); console.log(e.target.files[0])}} />
                    <br />
                    <input type='Submit' value='Save' onSubmit={UpdatePhotoUrl} />
                </form>
            </div>
        </Layout>
    );
}

export default withRouter(Profile);